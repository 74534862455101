@import "@/assets/scss/var.scss";
.courseDetail {
	background: #fff;
    .course-detail-page-content{
        max-width: initial;
    }
	.txt {
		font-size: 16px;
		color: #333;
		margin-bottom: 5px;
	}
	.over-hidden {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 5;
		overflow: hidden;
	}
	.talk {
		cursor: pointer;
		text-align: right;
		color: $color-primary;
	}
	// .talk:hover{
	// 	color: $color-primary;
	// }
	.vimeo,
	#course_vimeo {
		// height: 674px;
		// position: relative;
		// padding: 42.5% 0 0 0;
		// height: 200px;
		// overflow: hidden;
		> div {
			padding-top: 55.31% !important;
		}
		/deep/ iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.course_content {
		display: flex;
		position: relative;
		height: 100%;
		// 禁用滚动
		overflow: hidden;
		&-left {
			width: 100%;
			height: 100%;
			&.is-side {
				width: 75%;
				// 设置可滚动
				overflow-y: auto;
				// 隐藏滚动条
				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}
		&-right {
			position: sticky;
			right: 0;
			top: 0;
			bottom: 0;
			width: calc(25% - 15px);
			margin-left: 15px;
			// height: 390px;
			overflow: auto;
			height: auto;
      //height: fit-content;
		}
	}

	.course {
		&_title {
			margin: 10px 0;
			&-top {
				display: flex;
			}
			&-desc {
				color: $txt-color-grey;
			}
		}
		&_title-name,
		&-name {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 5px;
			flex: 1;
		}
		&-period {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			.txt {
				font-weight: bold;
			}
		}
		&-chapter {
			font-size: 12px;
			color: $txt-color-grey;
		}
		&-info {
			position: relative;
			.el-button {
				@media (min-width: $tablet) {
					display: none;
				}
				// margin-left: auto;
				// float: right;
				// align-self: flex-start;
				border: none;
				position: absolute;
				right: 0;
				top: 0;
				&:focus,
				&:hover {
					background: none;
				}
			}
		}
		&-detail {
			&-item {
				&__label {
					font-size: 20px;
					font-weight: bold;
					padding-bottom: 10px;
					margin-bottom: 10px;
					border-bottom: 1px solid #f1f1f1;
				}
				.course-comment {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.num {
					font-size: 14px;
					font-weight: normal;
				}
				&__content {
					display: flex;
					&-left {
						flex-basis: 20%;
					}
					.avatar {
						display: flex;
						align-items: center;
						&-label {
							margin-left: 10px;
							text-align: center;
						}
						&-name {
							font-weight: bold;
							margin-bottom: 5px;
						}
					}
					&-right {
						flex: 1;
					}
					margin-bottom: 20px;
				}
			}
		}
	}
	.chapter {
		@media (min-width: $tablet) {
			&-wrap {
				&[style*="display: none"] {
					display: block !important;
				}
			}
		}
		&-name {
			margin-bottom: 12px;
		}
		&_chpd {
			display: flex;
			margin-bottom: 10px;
			cursor: pointer;
			position: relative;
			.chpd-img {
				width: 40%;
				position: relative;
				.el-image {
					height: auto;
					vertical-align: top;
					@include thumb-square(56.25%);
				}
			}
			.chpd-cont {
				flex: 1;
				margin-left: 10px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
			&-stat {
				// position: absolute;
				// right: 0;
				// bottom: 0;
				// font-size: 12px;
				// color: #4cd964;
				> span {
					width: 10px;
					height: 10px;
					display: inline-block;
					border-radius: 100%;
				}
				.end {
					background: #339966;
				}
				.do {
					background: #373737;
					position: relative;
					> div {
						width: 6px;
						height: 6px;
						background: #fff;
						border-radius: 100%;
						position: absolute;
						top: 2px;
						left: 2px;
					}
				}
			}
			&-name {
				font-size: 12px;
				@extend .twoRows;
			}
			&-name:hover {
				color: $color-primary;
			}
			&-seconds {
				font-size: 12px;
				color: $txt-color-grey;
			}
			&.is-player {
				.chpd-playing {
					display: block;
					position: absolute;
					left: 0;
					padding: 3px 0;
					background: rgba(0, 0, 0, 0.8);
					bottom: 0;
					width: 100%;
					font-size: 12px;
					z-index: 1;
					text-align: center;
					color: #fff;
				}
				.chapter_chpd-name {
					color: $color-primary;
				}
			}
		}
	}
	[data-loader="rectangle"] {
		display: inline-block;
		position: relative;
		margin-left: 6px;
		margin-right: 10px;
		width: 3px;
		height: 10px;
		animation: rectangle infinite 1s ease-in-out -0.2s;
		background-color: $color-primary;
		&:before,
		&:after {
			position: absolute;
			bottom: 0;
			width: 3px;
			height: 4px;
			content: "";
			background-color: $color-primary;
		}
		&:before {
			left: -6px;
			animation: rectangle infinite 1s ease-in-out -0.4s;
		}
		&:after {
			right: -6px;
			animation: rectangle infinite 1s ease-in-out;
		}
	}
	.el-tabs {
		margin-top: $offset-v-lg;
		.el-tabs__nav .el-tabs__item {
			font-size: 16px;
			padding-right: $offset-h;
		}
	}
	.attach {
		padding: 0;
		margin: 0;
		display: flex;
		flex-flow: wrap;
		.el-link {
			margin-left: 10px;
		}
		li {
			list-style: none;
			// margin: $offset-v 0;
			margin-bottom: $offset-v;
			flex-basis: 50%;
		}
		a {
			display: flex;
			align-items: center;
		}
		[class*="el-icon"] {
			margin-right: 5px;
		}
		.el-button {
			margin-left: auto;
		}
	}
	.el-drawer__header {
		margin-bottom: 20px;
	}
	// .comment-switch {
	// 	text-align: center;
	// 	display: block;
	// 	// margin-bottom: 20px;
	// }
}
@keyframes rectangle {
	0%,
	100%,
	80% {
		height: 5px;
	}

	40% {
		height: 8px;
	}
}

#course_vimeo:not([data-vimeo-initialized]) {
	background: #f7f8fa;
	font-size: 75px;
	color: #dcdee0;
	@include thumb-square(56.25%);
	[class*="el-icon"] {
		opacity: 0.5;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
